.embla {
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 0vw;
  --slide-size: calc(
    100vw - 32px - env(safe-area-inset-left, 0px) -
      env(safe-area-inset-right, 0px)
  );
  position: relative;
  width: 100vw;
  margin-left: calc(-16px - env(safe-area-inset-left, 0px));
  margin-right: calc(-16px - env(safe-area-inset-right, 0px));
}

@media only screen and (min-width: 768px) and (max-width: 1366px) and (min-height: 800px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
  .embla {
    --slide-size: 80vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1366px) and (min-height: 600px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
  .embla {
    --slide-size: 80vw;
  }
}

@media only screen and (min-device-width: 1200px) and (pointer: fine) and (hover: hover) and (-webkit-min-device-pixel-ratio: 1) {
  .embla {
    --slide-size: 63.02vw;
    margin-left: -2.5vw;
    margin-right: -2.5vw;
  }
}

.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  gap: 12px;
}

.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem white;
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
  line-height: 680px;
}

.embla__slide__image {
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}
.embla__controls {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.embla__buttons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 84px;
  display: flex;
  justify-content: space-between;
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
  width: 18.9vw;
  border: none;
}

.embla__button--next {
  justify-content: flex-end;
}

.embla__button svg {
  filter: drop-shadow(0 0 25px #000);
  transition: all 0.5s ease;
  opacity: 0;
}

.embla__button:hover svg {
  opacity: 1;
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  height: 16px;
}

:root {
  /* border-radius */
  --simgle-img-border-radius: 5px;
  --incard-img-border-radius: 5px;
  --incard-vertical-img-border-radius: 5px;
  --incard-img-medium-border-radius: 10px;
  --incard-img-bigger-border-radius: 12px;
  --tag-border-radius: 8px;
  --card-border-radius: 10px;
  --bigger-card-radius: 12px;
  --tag-border-radius: 8px;
  --big-button-border-radius: 12px;
  --button-border-radius: 100px;
  --header-line-border-radius: 2px;
  --logo-nav-button-border-radius: 4px;
  --footer-border-radius: 12px 12px 0px 0px;

  /* paddings */
  --small-card-padding: 8px;
  --medium-card-padding: 16px;
  --padding-8-20: 8px;
  --padding-16-28: 16px;
  --padding-16: 16px;
  --padding-20: 20px;

  /* gaps */
  --gap-4: 4px;
  --gap-4-6: 4px;
  --gap-4-12: 4px;
  --gap-6: 6px;
  --gap-8: 8px;
  --gap-8-16: 8px;
  --gap-8-104: 8px;
  --gap-10: 10px;
  --gap-10-12: 10px;
  --gap-10-185: 10px;
  --gap-12: 12px;
  --gap-12-104: 12px;
  --gap-12-24: 12px;
  --gap-12-28: 12px;
  --gap-16: 16px;
  --gap-16-104: 16px;
  --gap-16-0: 16px;
  --gap-20: 20px;
  --gap-24: 24px;
  --gap-28: 28px;
  --gap-32: 32px;
  --gap-32-104: 32px;
  --gap-40: 40px;
  --gap-40-104: 40px;
  --gap-60-140: 60px;
  --gap-80-180: 80px;
}

/* laptops */
@media only screen and (min-device-width: 1200px) and (pointer: fine) and (hover: hover) and (-webkit-min-device-pixel-ratio: 1) {
  :root {
    /* border-radius */
    --incard-img-border-radius: 10px;
    --simgle-img-border-radius: 12px;
    --card-border-radius: 16px;
    --small-card-padding: 12px;
    --medium-card-padding: 28px;
    --tag-border-radius: 12px;
    --incard-vertical-img-border-radius: 8px;

    /* gaps */
    --gap-4-6: 6px;
    --gap-4-12: 12px;
    --gap-8-16: 16px;
    --gap-8-104: 1.04vw;
    --gap-10-12: 12px;
    --gap-10-185: 1.85vw;
    --gap-12-104: 1.04vw;
    --gap-12-24: 24px;
    --gap-12-28: 28px;
    --gap-16-104: 1.04vw;
    --gap-16-0: 0px;
    --gap-32-104: 1.04vw;
    --gap-40-104: 1.04vw;
    --gap-60-140: 140px;
    --gap-80-180: 180px;

    /* paddings */
    --padding-8-20: 20px;
    --padding-16-28: 28px;
  }
}

html {
  font-size: 16px;
  overflow: visible;
}

.no-scroll {
  overflow: hidden;
}

@keyframes tv {
  from {
    background-position-y: 0;
  }

  to {
    background-position-y: 100%;
  }
}

@keyframes th {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 100%;
  }
}

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 8px 12px;
  border-radius: 12px;
  color: white;
  font-size: 1.25rem;
  z-index: 1000;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.card-wrapper:hover .tooltip {
  opacity: 1;
}

.faded {
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* > a {
  color: inherit !important;
  text-decoration: none;
}

@font-face {
  font-family: "PP Neue Montreal Book";
  font-style: normal;
  font-weight: normal;
  src: local("PP Neue Montreal Book"),
    url("../assets/fonts/ppneuemontreal-book.woff") format("woff");
}

@font-face {
  font-family: "PP Neue Montreal Bold";
  font-style: normal;
  font-weight: normal;
  src: local("PP Neue Montreal Bold"),
    url("../assets/fonts/ppneuemontreal-bold.woff") format("woff");
}

@font-face {
  src: url("../assets/fonts/FormaDJRCyrillicText-Medium-Testing.woff2")
      format("woff2"),
    url("../assets/fonts/FormaDJRCyrillicText-Medium-Testing.woff")
      format("woff");
  font-family: "FormaDJRCyrillicText";
  font-style: normal;
  font-weight: 500;
}

@font-face {
  src: url("../assets/fonts/FormaDJRCyrillicText-Bold-Testing.woff2")
      format("woff2"),
    url("../assets/fonts/FormaDJRCyrillicText-Bold-Testing.woff") format("woff");
  font-family: "FormaDJRCyrillicTextBold";
  font-style: normal;
  font-weight: bold;
}

.ppbook {
  font-family: "PP Neue Montreal Book", Arial, Helvetica, sans-serif;
}

.ppmedium {
  font-family: "PP Neue Montreal Medium", Arial, Helvetica, sans-serif;
}

.ppbold {
  font-family: "PP Neue Montreal Bold", Arial, Helvetica, sans-serif;
}

.djrmedium {
  font-family: "FormaDJRCyrillicText", Arial, Helvetica, sans-serif;
}

.djrbold {
  font-family: "FormaDJRCyrillicTextBold", Arial, Helvetica, sans-serif;
}
